import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ShowyeMessageSummary,
  ShowyePageApiService,
  ShowyePageAuthService,
  ShowyePageMenuService,
  ShowyePageMessageService,
} from '@showye/showye-page-base';
import { MenuService, SettingsService } from '@delon/theme';
import { LayoutDefaultOptions } from '@delon/theme/layout-default';
import { NbApiService } from '@service/nb-api.service';
import { NbAvatarService } from '@service/nb-avatar.service';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

declare var CryptoJS: any;

@Component({
  selector: 'app-layout',
  styleUrls: ['layout.scss'],
  templateUrl: 'layout.component.html',
})
export class LayoutComponent {
  @Input() url: any;
  options: LayoutDefaultOptions = {
    logoExpanded: `./assets/logo-full.png`,
    logoCollapsed: `./assets/logo.png`,
  };
  totalCount = 0;
  totalMsgs: any[] = [];
  menus: any[] = [];
  menusUsed: any[] = [];
  passwordForm: FormGroup = this.fb.group({
    oldPass: [null, Validators.required],
    newPass: [null, Validators.required],
    repeatPass: [null, Validators.required],
  });
  showPassModal = false;

  get user(): any {
    return this.auth.user;
  }

  get reset(): string {
    return '/my/profile';
  }

  isExternal(link: string): boolean {
    return /^(https?:)?\/\//g.test(link!);
  }

  logout(): void {
    if (this.url) {
      window.location.href = this.url;
    }
    this.auth.logout();
  }

  buttonGroup: any;
  userId: any;
  downloadUrl = this.api.getDownloadUrl();
  staffAvatar = '/assets/avatar.svg';

  constructor(
    private fb: FormBuilder,
    private settings: SettingsService,
    private menuService: ShowyePageMenuService,
    private router: Router,
    private messageService: ShowyePageMessageService,
    private auth: ShowyePageAuthService,
    public api: ShowyePageApiService,
    private nbApi: NbApiService,
    private route: ActivatedRoute,
    protected menusService: MenuService,
    private nbAvatarService: NbAvatarService,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    // @ts-ignore
    this.buttonGroup = JSON.parse(window.localStorage.getItem('buttonGroup'));
    this.userId = this.auth.user.id;
    this.menuService.menuChange.subscribe((e: any) => {
      this.menuDeal(e);
      this.menus = e;
      this.getUserInfo();
      // 获取操作权限按钮
      this.getButtonsCode();
    });

    this.nbAvatarService.changeStream.subscribe(() => {
      this.getUserInfo();
      // 获取操作权限按钮
      this.getButtonsCode();
    });

    this.options = {
      logoExpanded: this.settings.app.logofull ? this.settings.app.logofull : this.options.logoExpanded,
      logoCollapsed: this.settings.app.logo ? this.settings.app.logo : this.options.logoCollapsed,
    };
    // tslint:disable-next-line:no-shadowed-variable
    this.messageService.message.subscribe((msg: ShowyeMessageSummary) => {
      this.totalCount = msg.unread;
      this.totalMsgs = msg.data;
    });
  }

  getButtonsCode(): void {
    // this.nbApi
    //   .myButton()
    //   .pipe(take(1))
    //   .subscribe((res: any) => {
    //     const data = res.data || [];
    //     const buttons = data.filter((x: any) => x.group === 3 && x.interfaceCode);
    //     this.buttonGroup = buttons.reduce((a: any, c: any) => ({ ...a, [c.interfaceCode]: true }), {});
    //     window.localStorage.setItem('buttonGroup', JSON.stringify(this.buttonGroup));
    //     // console.log(data, buttons, this.buttonGroup, 1111);
    //   });
  }

  // 获取被选中的菜单
  getMenuSelect(data: any): any[] {
    if (data?.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length > 0) {
          this.getMenuSelect(data[i].children);
        } else {
          this.menusUsed.push(data[i]);
        }
      }
    }
    return this.menusUsed;
  }

  // 菜单按钮权限不显示
  menuDeal(data: any[]): void {
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].group === 3) {
          data.splice(i, 1);
          i--;
        }
        if (data[i]?.children) {
          this.menuDeal(data[i].children);
        }
      }
    }
  }

  getUserInfo(): void {
    if (this.userId) {
      this.nbApi
        .userInfo(this.userId)
        .pipe(take(1))
        .subscribe((e: any) => {
          if (e.code === 200) {
            if (e.data?.avatar) {
              this.staffAvatar = this.downloadUrl + e.data.avatar;
            }
          }
        });
    }
  }

  /**
   * 加密（需要先加载assets/aes.min.js assets/crypto-js.min文件）
   * param word
   * returns {*}
   */
  encrypt(word: any): any {
    const key = CryptoJS.enc.Utf8.parse('1qazqwerascxzs67');
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  }

  onEditPassword(): void {
    this.passwordForm.reset({});
    this.showPassModal = true;
  }

  resetPassword(): void {
    const param = { ...this.passwordForm.value };
    if (param.repeatPass !== param.newPass) {
      this.msg.warning('两次输入的新密码不同，请检查后重试');
      return;
    }
    if (!param.oldPass) {
      this.msg.warning('请输入原密码');
      return;
    }
    this.nbApi
      .changeMyPassword({
        newPassword: this.encrypt(param.newPass),
        password: this.encrypt(param.oldPass),
      })
      .subscribe((res: any) => {
        if (res.code === 200) {
          this.showPassModal = false;
          this.notify.success(res.message, '');
        }
      });
  }
}
