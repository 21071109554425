import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '@service/api';
import { _HttpClient } from '@delon/theme';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  constructor(private api: API, protected http: _HttpClient) {}

  getAllowedRoutes(): Observable<any> {
    // 替换为你的后端API端点
    return this.http.get(this.api.MY_SHOW_PERMS);
  }
}
