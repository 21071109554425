import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService, TitleService } from '@delon/theme';

import { NzIconService } from 'ng-zorro-antd/icon';
import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { ShowyePageAuthService, ShowyePageMenuService } from '@showye/showye-page-base';
import { zip } from 'rxjs';
import { NbApiService } from '@service/nb-api.service';

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private settingService: SettingsService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    private menuService: ShowyePageMenuService,
    private authService: ShowyePageAuthService,
    private api: NbApiService,
    private auth: ShowyePageAuthService,
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }

  load(): Promise<any> {
    return new Promise((resolve) => {
      zip(this.api.listSystemConfigs(), this.auth.token ? this.api.showMenuTree() : '', this.auth.token ? this.api.myshowperms() : '')
        // .pipe(
        //   // tap(val => console.log(`BEFORE MAP: ${val}`)),
        //   // 接收其他拦截器后产生的异常消息
        //   catchError(([appData, menuData, permData]) => {
        //     // console.log('catch');
        //     resolve(null);
        //     return [appData, menuData, permData];
        //   }),
        // )
        .subscribe(
          ([appData, menuData, permData]) => {
            // 应用信息：包括站点名、描述、年份
            this.settingService.setApp(appData.data);
            if (appData.data.home) {
              // console.log('set home page by server config');
              this.menuService.setHomepage(appData.data.home);
            }
            // 用户权限信息
            this.authService.setPerms(permData.data);
            // 初始化菜单
            this.menuService.setFull(menuData.data);
            // 设置页面标题的后缀
            this.titleService.default = '';
            this.titleService.suffix = appData.data.name;
          },
          (err: any) => {
            // console.log('startup err: ', err);
          },
          () => {
            // console.log(2);
            resolve(null);
          },
        );
    });
  }
}
