import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class API {
  public HOST = environment.host + environment.path;
  // 枚举字典相关
  public ENUM_LIST = `${this.HOST}/core/enum/list`;
  // 文件上传下载
  public UPLOAD = `${this.HOST}/core/file/upload`;
  public DOWNLOAD = `${this.HOST}/core/file/download`;
  /**
   * 用户管理
   */
  // 新增ott用户
  public ADD_OTT_USER = `${this.HOST}/crypto/user/generate-link`;
  //  ott用户注册
  public OTT_USER_GENERATE = `${this.HOST}/crypto/user/generate-user`;
  // 新增用户
  public ADD_USER = `${this.HOST}/admin/user/add`;
  // 编辑用户
  public EDIT_USER = `${this.HOST}/admin/user/edit`;
  // 获取用户详情
  public USER_INFO = (id: any) => `${this.HOST}/admin/user/info/${id}`;
  // 用户列表
  public USER_LIST = `${this.HOST}/admin/user/list`;
  // 修改密码
  public MY_CHANGE_PASSWORD = `${this.HOST}/admin/user/password/reset`;
  // 修改密码--没有登录
  public NO_LOGIN_CHANGE_PASSWORD = `${this.HOST}/public/admin/password/reset`;
  // 修改密码超期天数
  public DAY_PASSWORD = `${this.HOST}/view/editPasswordExpireDay`;
  // 修改容量阀值
  public LOG_THRESHOLD = `${this.HOST}/view/editLogThreshold`;
  // 判断是不是超过容量阀值
  public LOG_THRESHOLD_INFO = `${this.HOST}/view/getLogThreshold`;
  // 审计日志
  public LOG_THRESHOLD_CHECK = (id: any) => `${this.HOST}/admin/log/check/${id}`;
  // 根据部门获取员工信息
  public GET_USER_LIST_BY_DOMAIN_ID = (domainId: any) => `${this.HOST}/admin/user/getUserListByDomainId/${domainId}`;
  // 编辑用户头像
  public EDIT_AVATAR = `${this.HOST}/admin/user/editAvatar`;
  public DELETE_USER = (id: any) => `${this.HOST}/admin/user/delete/${id}`;
  // 重置员工登录密码
  public INIT_PWD = (id: any) => `${this.HOST}/admin/user/initPwd/${id}`;
  // 用户列表下拉选择
  public GET_USER_LIST = `${this.HOST}/admin/user/getUserList`;
  // 根据部门查询用户列表
  public GET_USER_LIST_BY_DOMAIN = (domainId: any) => `${this.HOST}/admin/user/getUserListByDomainId/${domainId}`;
  /**
   * 组织机构管理
   */
  // 组织机构树
  public DOMAIN_TREE = `${this.HOST}/admin/domain/tree`;
  // 根据组织机构id获取同等级的组织机构列表
  public GET_SAME_LEVEL_DOMAIN_LIST = (id: any) => `${this.HOST}/admin/domain/getSameLevelDomainList/${id}`;
  /**
   * 角色管理
   */
  // 角色列表
  public ROLE_LIST = `${this.HOST}/admin/role/list`;
  // 新增角色
  public ROLE_ADD = `${this.HOST}/admin/role/add`;
  // 编辑角色
  public ROLE_EDIT = `${this.HOST}/admin/role/edit`;
  // 角色详情
  public ROLE_INFO = (id: any) => `${this.HOST}/admin/role/info/${id}`;
  // 删除角色
  public ROLE_DELETE = (id: any) => `${this.HOST}/admin/role/delete/${id}`;
  // 配置角色菜单
  public ROLE_CONFIG = `${this.HOST}/admin/role/roleConfig`;
  // 角色下拉选择
  public ALL_ROLES = `${this.HOST}/admin/role/allRoles`;
  // 获取部门下的角色
  public GET_ROLE_LIST_BY_DOMAIN = (domainId: any) => `${this.HOST}/admin/role/getRoleListByDomainId/${domainId}`;

  /**
   * 菜单管理
   */
  // 菜单树
  public MENU_TREE = `${this.HOST}/admin/menu/tree`;
  public SHOW_MENU_TREE = `${this.HOST}/admin/menu/showMenus`;
  // 删除菜单
  public MENU_DELETE = `${this.HOST}/admin/menu/delete`;
  // 菜单按钮权限
  public MY_PERMS = `${this.HOST}/admin/menu/myperms`;

  // 菜单按钮权限
  public MY_SHOW_PERMS = `${this.HOST}/admin/menu/myShowPerms`;

  /**
   *  权限按钮管理
   */
  // 权限树
  public BUTTON_TREE = `${this.HOST}/admin/button/buttonTree`;
  // 新增按钮
  public BUTTON_ADD = `${this.HOST}/admin/button/add`;
  // 编辑按钮
  public BUTTON_EDIT = `${this.HOST}/admin/button/edit`;
  // 删除按钮
  public BUTTON_DELETE = (id: any) => `${this.HOST}/admin/button/delete/${id}`;

  /**
   * 权限组管理
   */
  // 权限组列表
  public AUTH_GROUP_LIST = `${this.HOST}/admin/authGroup/list`;
  // 新增权限组
  public AUTH_GROUP_ADD = `${this.HOST}/admin/authGroup/add`;
  // 修改权限组
  public AUTH_GROUP_EDIT = `${this.HOST}/admin/authGroup/edit`;
  // 删除权限组
  public AUTH_GROUP_DELETE = (id: any) => `${this.HOST}/admin/authGroup/delete/${id}`;

  /**
   * 身份管理
   */
  // 身份列表
  public IDENTITY_LIST = `${this.HOST}/identity/list`;
  // 生成证书
  public IDENTITY_ADD = `${this.HOST}/identity/add`;
  // 编辑证书
  public IDENTITY_EDIT = (id: any) => `${this.HOST}/identity/edit/${id}`;
  // 删除证书
  public IDENTITY_DEL = (id: any) => `${this.HOST}/identity/deleteIdentity/${id}`;
  // 下载证书
  public IDENTITY_DOWNLOAD = (id: any) => `${this.HOST}/identity/getIdentityJwt/${id}`;
  // 重置证书
  public IDENTITY_RESET = (id: any) => `${this.HOST}/identity/restJwt/${id}`;
  // 现有身份标签名称列表
  public IDENTITY_ROLES = `${this.HOST}/identity/roles`;
  // 获取身份标签详情列表
  public IDENTITY_ROLE_LIST = `${this.HOST}/identity/roleList`;
  // 证书下拉
  public CA_LIST = `${this.HOST}/cas-certificate/ca-select`;
  // 身份验证策略下拉
  public AUTH_LIST = `${this.HOST}/auth-policy/auth-select`;
  // 获取所有身份列表
  public IDENTITY_ALL = `${this.HOST}/identity/all`;
  // 获取身份流量
  public IDENTITY_FLOW = `${this.HOST}/identity/flowListByIdentity`;
  // 添加设备
  public ADD_DEVICE = `${this.HOST}/device/add`;
  // 批量添加情报板
  public BATCH_ADD_BOARD = `${this.HOST}/device/batchAddBoard`;
  // 删除设备
  public DELETE_DEVICE = (id: any) => `${this.HOST}/device/delete/${id}`;
  // 禁用设备
  public DISABLE_DEVICE = (id: any) => `${this.HOST}/device/disable/${id}`;
  // 启用设备
  public ENABLE_DEVICE = (id: any) => `${this.HOST}/device/enable/${id}`;
  // 编辑设备
  public EDIT_DEVICE = `${this.HOST}/device/edit`;
  // 查看设备列表
  public GET_DEVICE_LIST = `${this.HOST}/device/getDeviceList`;
  // 获取所有设备
  public ALL_DEVICE = `${this.HOST}/device/allDevices`;
  // 查看设备基本信息
  public VIEW_DEVICE_BASE_INFO = (id: any) => `${this.HOST}/device/viewDeviceInfo/${id}`;
  // 查看设备类型列表
  public GET_DEVICE_TYPE_LIST = `${this.HOST}/device/getDeviceTypeList`;
  // 查看设备运行数据
  public VIEW_DEVICE_RUN_INFO = (id: any, dataType: any) => `${this.HOST}/device/viewDeviceData/${id}?dataType=${dataType}`;
  // 查看运行数据类型
  public QUERY_DEVICE_DATA_TYPE = `${this.HOST}/device/queryDeviceDataType`;
  // 新增机房
  public ADD_ROOM = `${this.HOST}/device/addRoom`;
  // 删除机房
  public DELETE_ROOM = (id: any) => `${this.HOST}/device/deleteRoom/${id}`;
  // 编辑机房
  public EDIT_ROOM = `${this.HOST}/device/editRoom`;
  // 查看机房列表
  public GET_ROOM_LIST = `${this.HOST}/device/getRoomList`;
  // 给设备设置阈值
  public SET_DEVICE_THRESHOLD = `${this.HOST}/device/setDeviceThreshold`;
  // 新增设备运行数据
  public ADD_DATATYPE_DEVICE_ = `${this.HOST}/device/addData`;
  // 编辑设备运行数据
  public EDIT_DATATYPE_DEVICE_ = `${this.HOST}/device/editData`;
  // 删除设备运行数据
  public DElETE_DATATYPE_DEVICE = (id: any) => `${this.HOST}/device/deleteData/${id}`;
  // 根据设备id查询设备接口
  public GET_PORT_BY_DEVICE_ID = (id: any) => `${this.HOST}/device/getPortByDeviceId/${id}`;
  // 查看流量详情
  public VIEW_FLOW_DTAIL = `${this.HOST}/device/viewFlowDtail`;
  // 查看流量明细
  public VIEW_FLOW_SPECIFICATION = (id: any) => `${this.HOST}/device/viewFlowSpecification/${id}`;
  // 新增端口
  public ADD_PORT = `${this.HOST}/device/addPort`;
  // 删除端口
  public DELETE_PORT = (id: any) => `${this.HOST}/device/deletePort/${id}`;
  // 查看报警列表
  public GET_ALARM_LISTS = `${this.HOST}/alarm/getAlarmList`;
  // 新增报警
  public ADD_ALARM = `${this.HOST}/alarm/add`;
  // 查看报警
  // public ALARM_VIEW = (id: any) => `${this.HOST}/alarm/view/${id}`;
  // 根据报警类型返回报警事件类型列表
  public GET_ALARM_EVENT_TYPE_LIST = `${this.HOST}/alarm/getAlarmEventTypeList`;
  // 处理报警
  public HANDLE_ALARM = `${this.HOST}/alarm/handle`;
  // 忽略报警
  public IGNORE_ALARM = `${this.HOST}/alarm/ignore`;
  // 删除报警
  public ALARM_DELETE = (id: any) => `${this.HOST}/alarm/delete/${id}`;
  // 查看设备报警事件
  public VIEW_DEVICE_ALARM = (id: any) => `${this.HOST}/device/viewDeviceAlarm/${id}`;
  // 查看密钥充注列表
  public QUERY_SK_FILL_LIST = `${this.HOST}/sk/querySkFillList`;
  // 查看密钥使用列表
  public QUERY_SK_USE_LIST = `${this.HOST}/sk/querySkUseList`;
  // 查看密钥设备列表
  public QUERY_SK_DEVICE_LIST = `${this.HOST}/sk/querySkDeviceList`;
  // 新增密钥充注
  public ADD_SK_FILL = `${this.HOST}/sk/addSkFill`;
  // 新增密钥使用
  public ADD_USE_FILL = `${this.HOST}/sk/addSkUse`;
  // 新增密钥设备
  public ADD_DEVICE_FILL = `${this.HOST}/sk/addSkDevice`;
  // 获取密钥设备类型列表
  public QUERY_SK_DEVICE_TYPE_LIST = `${this.HOST}/sk/getSkDeviceTypeList`;
  // 查看链路列表
  public GET_LINK_LIST = `${this.HOST}/link/getLinkList`;
  // 新增链路
  public ADD_LINK = `${this.HOST}/link/add`;
  // 禁用链路
  public DISABLE_LINK = (id: any) => `${this.HOST}/link/disable/${id}`;
  // 启用链路
  public ENABLE_LINK = (id: any) => `${this.HOST}/link/enable/${id}`;
  // 删除链路
  public DELETE_LINK = (id: any) => `${this.HOST}/link/delete/${id}`;
  // 编辑链路
  public EDIT_LINK = `${this.HOST}/link/edit`;
  // 批量设置链路报警阈值
  public SET_ALL_THRESHOLD = `${this.HOST}/link/setAllThreshold`;
  // 查看链路历史数据列表
  public VIEW_DATA_LIST = (id: any) => `${this.HOST}/link/viewDataList/${id}`;
  // 设置链路报警阈值
  public SET_THRESHOLD = `${this.HOST}/link/setThreshold`;
  // 编辑链路历史数据
  public EDIT_DATA = `${this.HOST}/link/editData`;
  // 删除链路历史数据
  public DELETE_DATA = (id: any) => `${this.HOST}/link/deleteData/${id}`;
  // 修改平台名字
  public EDIT_PLAT_FORM_NAME = `${this.HOST}/view/editPlatFormName`;
  // 查看平台名字
  public GET_PLAT_FORM_NAME = `${this.HOST}/view/getPlatFormName`;
  // 编辑设备类型
  public EDIT_DEVICE_TYPE = `${this.HOST}/device/editDeviceType`;

  /**
   * 检查管理
   */
  // 检查列表
  public POSTURE_LIST = `${this.HOST}/posture/list`;
  // 检查类型列表
  public POSTURE_CHECK_TYPES = `${this.HOST}/posture/postureCheckTypes`;
  // 添加检查
  public ADD_POSTURE_CHECK = `${this.HOST}/posture/addPostureCheck`;
  // 编辑检查
  public EDIT_POSTURE_CHECK = (id: any) => `${this.HOST}/posture/editPostureCheck/${id}`;
  // 删除检查
  public DEl_POSTURE_CHECK = (id: any) => `${this.HOST}/posture/del/${id}`;

  /**
   * 配置管理
   */
  // 配置列表
  public CONFIG_LIST = `${this.HOST}/config/list`;
  // 新增配置
  public ADD_CONFIG = `${this.HOST}/config/add`;
  // 编辑配置
  public EDIT_CONFIG = (id: any) => `${this.HOST}/config/edit/${id}`;
  // 删除配置
  public DELETE_CONFIG = (id: any) => `${this.HOST}/config/del/${id}`;
  // 获取详情
  public CONFIG_INFO = (id: any) => `${this.HOST}/config/info/${id}`;
  // 获取配置类型
  public CONFIG_TYPES = `${this.HOST}/config/configTypes`;

  /**
   * 服务管理
   */
  // 新增服务
  public ADD_SERVICE = `${this.HOST}/service/add`;
  // 删除服务
  public DELETE_SERVICE = (id: any) => `${this.HOST}/service/delete/${id}`;
  // 服务列表
  public SERVICE_LIST = `${this.HOST}/service/list`;
  // 编辑服务
  public UPDATE_SERVICE = (id: any) => `${this.HOST}/service/update/${id}`;
  // 获取服务详情
  public GET_SERVICE_BY_ID = (id: any) => `${this.HOST}/service/getServiceById/${id}`;
  // 获取服务标签列表
  public GET_SERVICE_ROLE_LIST = `${this.HOST}/service/getServiceRoleList`;
  /**
   * 访问控制
   */
  // 新增/编辑访问控制
  public ADD_POLICY_CONFIG = `${this.HOST}/policy-config/add`;
  // 批量添加访问控制
  public BATH_POLICY_CONFIG = `${this.HOST}/policy-config/addBathPolicyConfig`;
  // 删除访问控制
  public DELETE_POLICY_CONFIG = (id: any) => `${this.HOST}/policy-config/del/${id}`;
  // 访问控制列表
  public POLICY_CONFIG_LIST = `${this.HOST}/policy-config/list`;
  // 获取访问控制详情
  public GET_POLICY_CONFIG_BY_ID = (id: any) => `${this.HOST}/policy-config/info/${id}`;

  /**
   * 服务策略相关
   */
  // 策略列表
  public POLICY_LIST = `${this.HOST}/service-policy/list`;
  // 获取身份选择器---返回CODE
  public IDENTITY_ROLE = `${this.HOST}/identity/identity-role`;
  public IDENTITY_ROLE_TAG = `${this.HOST}/identity/identity-role-tag`;
  // 获取身份选择器----返回ID
  public IDENTITY_ROLE_SELECT = `${this.HOST}/identity/identity-role-select`;
  // 获取身份设备选择器----返回ID
  public IDENTITY_ROLE_DEVICE_SELECT_TYPE = (type: any) => `${this.HOST}/identity/identity-role-device-select/${type}`;
  // 身份标签
  public IDENTITY_ROLE_DEVICE_TAG = `${this.HOST}/identity/identity-role-tag`;
  // 获取服务选择器
  public SERVICE_ROLE = `${this.HOST}/service/service-role`;
  // 获取检查选择器
  public SERVICE_POSTURE = `${this.HOST}/posture/posture-select`;
  // 新增策略
  public ADD_POLICY = `${this.HOST}/service-policy/add`;
  // 编辑策略
  public EDIT_POLICY = (id: any) => `${this.HOST}/service-policy/edit/${id}`;
  // 获取策略详情
  public POLICY_INFO = (id: any) => `${this.HOST}/service-policy/info/${id}`;
  // 删除策略
  public DELETE_POLICY = (id: any) => `${this.HOST}/service-policy/del/${id}`;

  public DEVICE_BY_TYPE = (type: any) => `${this.HOST}/device/deviceListByType/${type}`;
  /**
   * auth策略相关
   */
  // auth策略列表
  public AUTH_POLICY_LIST = `${this.HOST}/auth-policy/list`;
  // 删除auth策略
  public DELETE_AUTH_POLICY = (id: any) => `${this.HOST}/auth-policy/del/${id}`;
  // 新增auth策略
  public ADD_AUTH_POLICY = `${this.HOST}/auth-policy/add`;
  // 编辑auth策略
  public EDIT_AUTH_POLICY = (id: any) => `${this.HOST}/auth-policy/edit/${id}`;
  // 获取auth策略详情
  public AUTH_POLICY_INFO = (id: any) => `${this.HOST}/auth-policy/info/${id}`;

  /**
   * JWT策略相关
   */
  // JWT策略列表
  public JWT_POLICY_LIST = `${this.HOST}/jwt-policy/list`;
  // 删除JWT策略
  public DELETE_JWT_POLICY = (id: any) => `${this.HOST}/jwt-policy/del/${id}`;
  // 新增JWT策略
  public ADD_JWT_POLICY = `${this.HOST}/jwt-policy/add`;
  // 编辑JWT策略
  public EDIT_JWT_POLICY = (id: any) => `${this.HOST}/jwt-policy/edit/${id}`;
  // 获取JWT策略详情
  public JWT_POLICY_INFO = (id: any) => `${this.HOST}/jwt-policy/info/${id}`;
  // 获取JWT策略选择器
  public AUTH_JWT_ROLE = `${this.HOST}/jwt-policy/jwt-role`;
  /**
   * 证书管理
   */
  // 证书列表
  public CERTIFICATE_LIST = `${this.HOST}/cas-certificate/list`;
  // 删除证书
  public DELETE_CERTIFICATE = (id: any) => `${this.HOST}/cas-certificate/del/${id}`;
  // 新增证书
  public ADD_CERTIFICATE = `${this.HOST}/cas-certificate/add`;
  // 编辑证书
  public EDIT_CERTIFICATE = (id: any) => `${this.HOST}/cas-certificate/edit/${id}`;
  // 获取证书详情
  public CERTIFICATE_INFO = (id: any) => `${this.HOST}/cas-certificate/info/${id}`;
  /**
   * CA证书管理
   */
  // 证书列表
  public CA_CERTIFICATE_LIST = `${this.HOST}/caCertificate/list`;
  // 删除证书
  public DELETE_CA_CERTIFICATE = (id: any) => `${this.HOST}/caCertificate/del/${id}`;
  // 新增证书
  public CA_CERTIFICATE_ADD = `${this.HOST}/caCertificate/add`;
  // 下载CA证书
  public CA_CERTIFICATE_DOWNLOAD = (id: any) => `${this.HOST}/caCertificate/download/${id}`;
  /****
   * 随机数配置
   */
  // 新增随机数配置
  public RANDOM_CONFIG_ADD = `${this.HOST}/random-config/add`;
  // 随机数配置详情
  public RANDOM_CONFIG_INFO = `${this.HOST}/random-config/info`;
  // 随机数配置-设备下拉
  public RANDOM_CONFIG_DEV = `${this.HOST}/random-config/file-select`;
  // IP地址列表
  public IP_ADDRESS_LIST = `${this.HOST}/ip-address/list`;
  // IP地址列表
  public IP_ADDRESS_DEL = (id: any) => `${this.HOST}/ip-address/del/${id}`;
  // IP地址详情
  public IP_ADDRESS_INFO = (id: any) => `${this.HOST}/ip-address/info/${id}`;
  // IP地址新增
  public ADD_IP_ADDRESS = `${this.HOST}/ip-address/add`;
  // 编辑ip地址
  public UPDATE_IP_ADDRESS = (id: any) => `${this.HOST}/ip-address/edit/${id}`;

  /**
   * 可视化
   */
  // 查看平台名字
  // public GET_PLAT_FORM_NAME = `${this.HOST}/view/getPlatFormName`;
  // 设备统计
  public DEVICE_STATISTICS = `${this.HOST}/view/deviceStatistics`;
  // 情报板统计
  public BOARD_STAT = (type: any) => `${this.HOST}/view/boardStat?period=${type}`;
  // 设备受攻击统计
  public DEVICE_ATTACK = `${this.HOST}/view/attack`;
  // 查看设备类型列表
  // public GET_DEVICE_TYPE_LIST = `${this.HOST}/device/getDeviceTypeList`;
  // 拓扑图信息
  public GET_TOPOLOGICAL_GRAPH = `${this.HOST}/view/getTopologicalGraph`;
  // 保存拓扑图
  public ADD_TOPOLOGICAL_GRAPH = `${this.HOST}/view/addTopologicalGraph`;
  // 根据链路id查链路流量
  public GET_LINK_TRAFFIC = (id: any) => `${this.HOST}/view/getLinkTraffic/${id}`;
  // 返回所有的情报板链路
  public GET_INFORMATION_BOARD_LINK = `${this.HOST}/view/getInformationBoardLink`;
  // 今日报警数量统计
  public TODAY_ALARM_COUNT = `${this.HOST}/view/todayAlarmCount`;
  // 查看报警次数为前五的设备
  public QUERY_TOP_ALARM_DEVICE = `${this.HOST}/view/queryTopAlarmDevice`;
  // 查看可视化报警列表
  public GET_ALARM_LIST = `${this.HOST}/view/getAlarmList`;
  // 处理报警
  // public HANDLE_ALARM = `${this.HOST}/alarm/handle`;
  // 查看设备基本信息
  public VIEW_DEVICE_INFO = (id: any) => `${this.HOST}/view/viewDeviceInfo/${id}`;
  // 设备报警数量统计
  public DEVICE_ALARM_COUNT = (id: any) => `${this.HOST}/view/deviceAlarmCount/${id}`;
  // 设备性能数据
  public DEVICE_PERFORMANCE_DATA = (id: any) => `${this.HOST}/view/devicePerformanceData/${id}`;
  // 设备报警列表
  public GET_DEVICE_ALARM_LIST = `${this.HOST}/view/getDeviceAlarmList`;
  // 设备CPU利用率分析
  public CPU_UTILIZATION_ANALYSE = (id: any) => `${this.HOST}/view/cpuUtilizationAnalyse/${id}`;
  // 设备内存占有率分析
  public MEMORY_OCCUPANCY_ANALYSE = (id: any) => `${this.HOST}/view/memoryOccupancyAnalyse/${id}`;
  // 设备温度分析
  public DEVICE_TEMPERATURE_ANALYSE = (id: any) => `${this.HOST}/view/deviceTemperatureAnalyse/${id}`;
  // 设备端口总流量分析
  public PORT_TRAFFIC_ANALYSE = (id: any) => `${this.HOST}/view/portTrafficAnalyse/${id}`;
  // 链路统计
  public LINK_STATISTICS = `${this.HOST}/view/linkStatistics`;
  // 密钥趋势
  public SK_TREND = `${this.HOST}/view/sk/trend`;
  // 事件情况统计
  public ALARM_STAT = (type: any) => `${this.HOST}/view/alarmStat?period=${type}`;
  // 数据库数据迁移--更新数据库
  public DATA_BASE = `${this.HOST}/update/data/database`;
  // 数据库数据迁移 --更新数据
  public DATA_BASE_DATA = `${this.HOST}/update/data/data`;

  /***
   * 查询日志
   */
  public LIST_LOG = `${this.HOST}/admin/log/list`;
  /***
   * 获取验证码
   */
  public CAPTCHA_IMAGE = `${this.HOST}/public/core/captchaImage`;
}
