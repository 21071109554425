import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RouteGuardService } from './route-guard';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate, CanActivateChild {
  constructor(private routeGuardService: RouteGuardService, private router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkRouteAccess(state.url);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkRouteAccess(state.url);
  }

  private checkRouteAccess(url: string): Observable<boolean> {
    return this.routeGuardService.getAllowedRoutes().pipe(
      map((allowedRoutes) => {
        const currentRoute = url;
        const ts = [];
        // 使用some方法检查数组中是否有对象的interfaceCode属性等于viewIndex放行可视化页面 首选第一个
        const hasCode = allowedRoutes?.data?.buttons.some((item: { interfaceCode: string }) => item.interfaceCode === 'viewIndex');
        if (hasCode) {
          ts.push('/view');
        }
        //  获取权限菜单
        allowedRoutes?.data?.menus.forEach((item: any) => {
          if (item.link !== undefined && item.link !== '') {
            ts.push(item?.link);
          }
        });

        // 重新定义按钮权限
        const buttons = allowedRoutes?.data?.buttons.filter((b: any) => b.interfaceCode);
        const buttonGroup = buttons.reduce((a: any, c: any) => ({ ...a, [c.interfaceCode]: true }), {});
        window.localStorage.setItem('buttonGroup', JSON.stringify(buttonGroup));
        // 存在修改权限后可能首页位不一样， 重新设置首页位
        const homePath = window.localStorage.getItem('homePath') || '/passport/login';
        if (!ts.includes(homePath)) {
          window.localStorage.setItem('homePath', ts[0]);
        }
        return ts.includes(currentRoute);
      }),

      catchError(() => {
        // 如果获取路由失败，可以选择重定向到登录页面或返回false
        this.router.navigate(['/passport/login']);
        return of(false);
      }),
    );
  }
}
